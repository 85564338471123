<template>
  <div id="perTradesInfo">
    <ul class="trades_list">
      <li :class="classShowActive('stp')" @click="switchType('stp')">
        {{ $t('promotion.cashback.button.stp') }}
      </li>
      <li :class="classShowActive('ecn')" @click="switchType('ecn')">
        {{ $t('promotion.cashback.button.ecn') }}
      </li>
    </ul>
    <div class="trades_content">
      <div v-for="item in currentTradesTypeData" :key="item.tradingGroup">
        <p class="sub_title">{{ $t(`promotion.cashback.subTitle.${item.tradingGroup}`) }}</p>
        <span class="amount">{{ item.ratio }}</span>
        <span>{{ $t('promotion.cashback.subTitle.perLot') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PerTradesInfo',
  props: {
    stpRatio: {
      type: Array,
      default: () => []
    },
    ecnRatio: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentTradesType: 'stp'
    };
  },
  methods: {
    switchType(type) {
      this.currentTradesType = type;
    },
    classShowActive(type) {
      if (type === this.currentTradesType) return 'active';
    }
  },
  computed: {
    currentTradesTypeData() {
      return this.currentTradesType === 'stp' ? this.stpRatio : this.ecnRatio;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/cashback/perTradesInfo.scss';
</style>
