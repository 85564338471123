<template>
  <div id="cashbackRundown">
    <div class="content_box">
      <div class="inner_max">
        <div>
          <h3>{{ $t('promotion.cashback.title.claim') }}</h3>
          <div class="first_content">
            <p>{{ $t('promotion.cashback.content.claimContent1') }}</p>
            <br />
            <p>{{ $t('promotion.cashback.content.claimContent2') }}</p>
          </div>
        </div>

        <img src="@/assets/images/promotion/cashback/green_arrow.png" alt="green arrow" />

        <div>
          <h3>{{ $t('promotion.cashback.title.howItWorks') }}</h3>
          <div class="process_box">
            <div>
              <div class="process" v-for="index in 3" :key="index">
                <div class="step_box">
                  <div class="step">{{ index }}</div>
                  <b>{{ $t(`promotion.cashback.subTitle.step${index}`) }}</b>
                  <span>{{ $t(`promotion.cashback.content.howItWorksContent${index}`) }}</span>
                </div>
                <img
                  v-if="index < 3"
                  src="@/assets/images/promotion/cashback/three_green_arrow.png"
                  alt="three green arrow"
                />
              </div>
            </div>

            <el-button @click="$router.push('depositFunds')">{{
              $t('promotion.cashback.button.depositNow')
            }}</el-button>
          </div>
        </div>

        <img src="@/assets/images/promotion/cashback/green_arrow.png" alt="green arrow" />

        <div class="per_trades_info">
          <h3>{{ $t('promotion.cashback.title.howMuch') }}</h3>
          <PerTradesInfo :stpRatio="stpRatio" :ecnRatio="ecnRatio" />
        </div>

        <div>
          <h3>{{ $t('promotion.cashback.tnc.title') }}</h3>
          <el-collapse accordion>
            <el-collapse-item :title="$t('promotion.cashback.tnc.title')" name="1">
              <TnC />
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiCampaignInfo } from '@/resource';
import PerTradesInfo from '@/components/cashback/PerTradesInfo';
import TnC from '@/components/cashback/TnC';

export default {
  name: 'CashbackRundown',
  components: { PerTradesInfo, TnC },
  data() {
    return {
      stpRatio: [],
      ecnRatio: []
    };
  },
  computed: {
    campaignID() {
      let campaign = this.$store.state.promotion.eligibleCampaigns.find(c => c.campaignType === 'CASHBACK_2');
      return campaign ? campaign.id : null;
    }
  },
  methods: {
    getCashbackInfo() {
      apiCampaignInfo(this.campaignID)
        .then(resp => {
          if (resp.data.code === 0) {
            this.stpRatio = resp.data.data.stpRatio;
            this.ecnRatio = resp.data.data.ecnRatio;
          }
        })
        .catch(error => {
          this.showError();
        });
    },
    showError() {
      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error',
        duration: 5000
      });
    }
  },
  mounted() {
    this.getCashbackInfo();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/cashBackRundown.scss';
</style>
