<template>
  <div class="tnc_content">
    <div class="title">{{ $t('promotion.cashback.tnc.content.item1.title') }}</div>
    <ol>
      <li>
        {{ $t('promotion.cashback.tnc.content.item1.li1') }}
      </li>
      <li>
        {{ $t('promotion.cashback.tnc.content.item1.li2') }}
      </li>
    </ol>

    <div class="title">{{ $t('promotion.cashback.tnc.content.item2.title') }}</div>
    <ol>
      <li>
        {{ $t('promotion.cashback.tnc.content.item2.li1') }}
      </li>
      <li>
        {{ $t('promotion.cashback.tnc.content.item2.li2') }}
      </li>
      <li>
        {{ $t('promotion.cashback.tnc.content.item2.li3') }}
      </li>
      <li>
        {{ $t('promotion.cashback.tnc.content.item2.li4') }}
      </li>
      <li>
        {{ $t('promotion.cashback.tnc.content.item2.li5') }}
      </li>
      <li>
        {{ $t('promotion.cashback.tnc.content.item2.li6') }}
        <ol class="sub_item">
          <li>
            {{ $t('promotion.cashback.tnc.content.item2.li6_sub_items.li1') }}
          </li>
          <li>
            {{ $t('promotion.cashback.tnc.content.item2.li6_sub_items.li2') }}
          </li>
          <li>
            {{ $t('promotion.cashback.tnc.content.item2.li6_sub_items.li3') }}
          </li>
        </ol>
      </li>
    </ol>

    <div class="title">{{ $t('promotion.cashback.tnc.content.item3.title') }}</div>
    <ol>
      <li>
        {{ $t('promotion.cashback.tnc.content.item3.li1') }}
        <p>
          {{ $t('promotion.cashback.tnc.content.item3.li1_sub_desc') }}
        </p>
      </li>
      <li>
        {{ $t('promotion.cashback.tnc.content.item3.li2') }}
      </li>
      <li>
        {{ $t('promotion.cashback.tnc.content.item3.li3') }}
        <div class="sub_title">
          <b>{{ $t('promotion.cashback.tnc.content.item3.li3_sub_content.title1') }}</b>
        </div>
        <div>{{ $t('promotion.cashback.tnc.content.item3.li3_sub_content.info1') }}</div>
        <div>{{ $t('promotion.cashback.tnc.content.item3.li3_sub_content.info2') }}</div>
        <div class="sub_title">
          <b>{{ $t('promotion.cashback.tnc.content.item3.li3_sub_content.title2') }}</b>
        </div>
        <div>{{ $t('promotion.cashback.tnc.content.item3.li3_sub_content.info3') }}</div>
        <div>{{ $t('promotion.cashback.tnc.content.item3.li3_sub_content.info4') }}</div>
        <p>
          {{ $t('promotion.cashback.tnc.content.item3.li3_sub_desc') }}
        </p>
      </li>
      <li>{{ $t('promotion.cashback.tnc.content.item3.li4') }}</li>
      <li>
        {{ $t('promotion.cashback.tnc.content.item3.li5') }}
      </li>
      <li>{{ $t('promotion.cashback.tnc.content.item3.li6') }}</li>
      <li>
        {{ $t('promotion.cashback.tnc.content.item3.li7') }}
      </li>
      <li>
        {{ $t('promotion.cashback.tnc.content.item3.li8') }}
      </li>
      <li>
        {{ $t('promotion.cashback.tnc.content.item3.li9') }}
        <ol class="sub_item">
          <li>{{ $t('promotion.cashback.tnc.content.item3.li9_sub_items.li1') }}</li>
          <li>
            {{ $t('promotion.cashback.tnc.content.item3.li9_sub_items.li2') }}
          </li>
        </ol>
      </li>
      <li>
        {{ $t('promotion.cashback.tnc.content.item3.li10') }}
      </li>
      <li>
        {{ $t('promotion.cashback.tnc.content.item3.li11') }}
      </li>
      <li>
        {{ $t('promotion.cashback.tnc.content.item3.li12') }}
      </li>
      <li>
        {{ $t('promotion.cashback.tnc.content.item3.li13') }}
      </li>
      <li>
        {{ $t('promotion.cashback.tnc.content.item3.li14') }}
      </li>
      <li>
        {{ $t('promotion.cashback.tnc.content.item3.li15') }}
      </li>
      <li>{{ $t('promotion.cashback.tnc.content.item3.li16') }}</li>
      <li>{{ $t('promotion.cashback.tnc.content.item3.li17') }}</li>
    </ol>

    <div class="title">{{ $t('promotion.cashback.tnc.content.item4.title') }}</div>
    <ol>
      <li>
        {{ $t('promotion.cashback.tnc.content.item4.li1') }}
      </li>
    </ol>
  </div>
</template>
<script>
export default {
  name: 'TnC'
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/components/cashback/tnc.scss';
</style>
